<template>
    <vs-chip class="ag-grid-cell-chip" :color="chipColor(params.data.action)">
        <span>{{ organizationTypeString(params.data.action) }}</span>
    </vs-chip>
</template>

<script>
export default {
    name: 'CellRendererStatus',
    computed: {
        chipColor() {
            return (value) => {
                switch (value) {
                    case 0:
                        return "primary";
                    case 1:
                        return "danger";
                    case 2:
                        return "success";
                    case 3:
                        return "danger";
                    case 4:
                    case 5:
                    case 6:
                        return "success";
                    default :
                        return "";
                }
            }
        },
        organizationTypeString() {
            return (value) => {
                switch (value) {
                    case 0:
                        return this.$t('cameraHistory.registerPackage.action.create');
                    case 1:
                        return this.$t('cameraHistory.registerPackage.action.deactivated');
                    case 2:
                        return this.$t('cameraHistory.registerPackage.action.reactivated');
                    case 3:
                        return this.$t('cameraHistory.registerPackage.action.delete');
                    case 4:
                        return this.$t('cameraHistory.registerPackage.action.updateLinkStream');
                    case 5:
                        return this.$t('cameraHistory.registerPackage.action.updateTimeStorage');
                    case 6:
                        return this.$t('cameraHistory.registerPackage.action.updateInformation');
                    default:
                        '';
                }
            }
        }
    },
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
    &.vs-chip-success {
        background: rgba(var(--vs-success), .15);
        color: rgba(var(--vs-success), 1) !important;
        font-weight: 500;
    }

    &.vs-chip-warning {
        background: rgba(var(--vs-warning), .15);
        color: rgba(var(--vs-warning), 1) !important;
        font-weight: 500;
    }

    &.vs-chip-danger {
        background: rgba(var(--vs-danger), .15);
        color: rgba(var(--vs-danger), 1) !important;
        font-weight: 500;
    }

    &.vs-chip-primary {
        background: rgba(var(vs-primary), .15);
        color: rgba(var(vs-primary), 1) !important;
        font-weight: 500;
    }

    &.vs-chip-dark {
        background: rgba(var(vs-dark), .15);
        color: rgba(var(vs-dark), 1) !important;
        font-weight: 500;
    }

    &.vs-chip-light {
        background: rgba(var(--vs-light), .15);
        color: rgba(var(--vs-light), 1) !important;
        font-weight: 500;
    }
}
</style>
