<template>
    <vs-chip class="ag-grid-cell-chip" :color="chipColor(params.data.paymentType)">
        <span>{{ organizationTypeString(params.data.paymentType) }}</span>
    </vs-chip>
</template>

<script>
export default {
    name: 'CellRendererPaymentStatus',
    computed: {
        chipColor() {
            return (value) => {
                switch (value) {
                    case 'momo':
                        return "success";
                    case 'admin':
                        return "danger";
                    case 6:
                            return ""
                }
            }
        },
        organizationTypeString() {
            return (value) => {
                switch (value) {
                    case 'momo':
                        return 'momo'.toUpperCase();
                    case 'admin':
                        return 'admin'.toUpperCase();
                    default :
                        return ''
                }
            }
        }
    },
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
    &.vs-chip-success {
        background: rgba(var(--vs-success),.15);
        color: rgba(var(--vs-success),1) !important;
        font-weight: 500;
    }
    &.vs-chip-warning {
        background: rgba(var(--vs-warning),.15);
        color: rgba(var(--vs-warning),1) !important;
        font-weight: 500;
    }
    &.vs-chip-danger {
        background: rgba(var(--vs-danger),.15);
        color: rgba(var(--vs-danger),1) !important;
        font-weight: 500;
    }
}
</style>
