<template>
    <div>
        <div class="vx-row mt-2 mb-2 items-center">
            <div class="vx-col sm:w-auto" style="display: inline-grid">
                <label class="vs-input--label">{{ $t('minioStorage.dateRange') }}</label>
                <date-range-picker
                    ref="picker"
                    :locale-data="{format: 'dd/mm/yyyy' }"
                    :opens="'right'"
                    @update="initScreen"
                    v-model="dateRange">
                </date-range-picker>
            </div>
            <div class="vx-col sm:w-1/4 md:w-1/4 lg:w-1/4 w-full">
                <label class="vs-input--label">Camera</label>
                <v-select class=""
                          v-model="cameraModel"
                          :clearable="false"
                          :options="cameraOptions"
                          data-vv-validate-on="blur">
                    <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                </v-select>
            </div>
            <div class="vx-col sm:w-1/4 md:w-1/4 lg:w-1/4 w-full">
                <label class="vs-input--label">{{$t('root.status')}}</label>
                <v-select class=""
                          v-model="actionModel"
                          :clearable="false"
                          :options="actions"
                          data-vv-validate-on="blur">
                    <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                </v-select>
            </div>
            <div class="vx-col sm:w-auto">
                <vs-button icon-pack="feather" @click="initScreen" class="ml-auto mt-6">{{ $t('button.search') }}</vs-button>
            </div>
        </div>
        <div class="flex flex-wrap items-center">
            <div class="flex-grow">
            </div>
            <vs-input class="float-right"
                      v-model="searchQuery"
                      @input="updateSearchQuery" :placeholder="$t('button.search')"/>
        </div>
        <!-- AgGrid Table -->
        <ag-grid-vue style="height: 55vh"
                     ref="agGridTable"
                     :components="components"
                     :gridOptions="gridOptions"
                     class="ag-theme-material w-100 my-4 ag-grid-table"
                     :columnDefs="columnDefs"
                     :defaultColDef="defaultColDef"
                     :rowData="registerPackageHistory"
                     :animateRows="true"
                     :pagination="true"
                     :localeText=" {noRowsToShow: this.$t('menuCamera.noRowsToShow')}"
                     :paginationPageSize="paginationPageSize"
                     :onGridReady="onGridReady"
                     :suppressPaginationPanel="true">
        </ag-grid-vue>

        <div class="vx-row items-center">
            <div class="vx-col md:w-1/2">
                <Pagination
                    :itemList="registerPackageHistory"
                    :currentPage="currentPage"
                    :paginationPageSize="paginationPageSize"
                    :gridApi="gridApi"
                />
            </div>
            <div class="vx-col md:w-1/2">
                <vs-pagination
                    :total="totalPages"
                    :max="maxPageNumbers"
                    v-model="currentPage"/>
            </div>
        </div>
    </div>
</template>
<script>
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Pagination from "../../../ui-elements/Pagination.vue"
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import vSelect from 'vue-select'
import DateRangePicker from '../../../../components/vue2-daterange-picker/components/DateRangePicker.vue'
import moment from 'moment'

export default {
    components: {
        AgGridVue,
        // Cell Renderer
        CellRendererActions,
        Pagination,
        vSelect,
        DateRangePicker,
        moment
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: this.$t('cameraHistory.registerPackage.columnDefs.alias'),
                    field: 'aliasId',
                    width: 150
                },
                {
                    headerName: this.$t('cameraHistory.registerPackage.columnDefs.cameraName'),
                    field: 'cameraName',
                    width: 150
                },
                {
                    headerName: this.$t('cameraHistory.registerPackage.columnDefs.createdDate'),
                    field: 'createdDate',
                    width: 120
                },
                {
                    headerName: this.$t('cameraHistory.registerPackage.columnDefs.status'),
                    field: 'action',
                    width: 120,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
            },
            registerPackageHistory: [],
            cameraGroupId: null,
            selectedCamera: null,
            cameraOptions: [],
            dateRange: {
                endDate: new Date(),
                startDate: (() => {
                    let date = new Date();
                    date.setDate(date.getDate() - 6);
                    date.setHours(0, 0, 0);
                    return date;
                })()
            },
            selectedAction: null,
            actions: [
                {value: null, label: this.$t('cameraHistory.registerPackage.action.all')},
                {value: 0, label: this.$t('cameraHistory.registerPackage.action.create')},
                {value: 1, label: this.$t('cameraHistory.registerPackage.action.deactivated')},
                {value: 2, label: this.$t('cameraHistory.registerPackage.action.reactivated')},
                {value: 3, label: this.$t('cameraHistory.registerPackage.action.delete')},
                {value: 4, label: this.$t('cameraHistory.registerPackage.action.updateLinkStream')},
                {value: 5, label: this.$t('cameraHistory.registerPackage.action.updateTimeStorage')},
                {value: 6, label: this.$t('cameraHistory.registerPackage.action.updateInformation')},
            ],
        }
    },
    async created() {
        await this.getCamera();
        this.initScreen();
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        cameraModel: {
            get() {
                let object = this.cameraOptions.find(el => el.value == this.selectedCamera);
                if (!object) return;
                return {
                    label: object.label,
                    value: this.selectedCamera
                }
            },
            set(object) {
                this.selectedCamera = object ? object.value : null;
                this.initScreen();
            }
        },
        actionModel: {
            get() {
                let object = this.actions.find(el => el.value == this.selectedAction);
                if (!object) return;
                return {
                    label: object.label,
                    value: this.selectedAction
                }
            },
            set(object) {
                this.selectedAction = object ? object.value : null;
                this.initScreen();
            }
        }
    },
    methods: {
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            this.$vs.loading();
            this.dateRange.startDate = new Date(this.dateRange.startDate.setHours(0, 0, 0));
            this.dateRange.endDate = new Date(this.dateRange.endDate.setHours(23, 59, 59));
            let url = `/camera/find-history-action?startDate=${this.dateRange.startDate.getTime()}&endDate=${this.dateRange.endDate.getTime()}
            ${this.selectedCamera ? '&cameraId=' + this.selectedCamera : ''}${this.selectedAction != null ? '&action=' + this.selectedAction : ''}`;
            console.log("url", url);
            this.$vs.loading();
            this.$crm.get(url).then((response) => {
                this.registerPackageHistory = [];
                if (response.data.length > 0) {
                    this.registerPackageHistory = response.data.reduce((arr, index) => {
                        index.createdDate = moment(index.createdDate).format("DD/MM/YYYY HH:mm:ss");
                        arr.push(index);
                        return arr;
                    }, []);
                }
                setTimeout(() => {
                    this.gridApi.sizeColumnsToFit();
                }, 200);
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getCamera() {
            return new Promise((resolve => {
                let url = '/camera/all';
                this.$vs.loading();
                this.$crm.post(url).then((response) => {
                    this.cameraOptions = response.data.map(el => {
                        return {
                            label: el.cameraName,
                            value: el.id
                        }
                    })
                    this.cameraOptions.unshift({value: null, label: this.$t('cameraHistory.registerPackage.action.all')})
                    resolve(true);
                    this.$vs.loading.close();
                }).catch((err) => {
                    resolve(true);
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            }))
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    }
}
</script>
