<template>
    <div class="vx-card p-6">
        <div class="vx-row">
            <div class="vx-col md:w-full flex items-center mb-2">
                <TitleScreen></TitleScreen>
            </div>
        </div>
        <vs-tabs>
            <vs-tab :label="$t('cameraHistory.actions.screenName')">
                <actions-history/>
            </vs-tab>

            <vs-tab :label="$t('cameraHistory.registerPackage.screenName')">
                <register-package-history/>
            </vs-tab>
        </vs-tabs>
    </div>
</template>

<script>
import RegisterPackageHistory from './RegisterPackageHistory.vue'
import ActionsHistory from './ActionsHistory.vue'
import TitleScreen from "../../../../layouts/main/TitleScreen";

export default {
    components: {
        RegisterPackageHistory,
        ActionsHistory,
        TitleScreen
    },
    data() {
        return {}
    },
    computed: {},
    methods: {},
    async created() {
    },
    mounted() {
    }
}
</script>
