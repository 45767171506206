<template>
    <div>
        <div class="vx-row mt-2 mb-2 items-center">
            <div class="vx-col sm:w-full md:w-1/3 lg:w-1/4 w-full">
                <label class="vs-input--label">{{ $t('minioStorage.dateRange') }}</label>
                <div class="w-full">
                    <date-range-picker
                        ref="picker"
                        :locale-data="{format: 'dd/mm/yyyy' }"
                        :opens="'right'"
                        @update="initScreen"
                        v-model="dateRange" class="w-full">
                    </date-range-picker>
                </div>
            </div>
            <div class="vx-col sm:w-full md:w-1/3 lg:w-1/4 w-full">
                <label class="vs-input--label">Camera</label>
                <v-select class=""
                          v-model="cameraModel"
                          :clearable="false"
                          :options="cameraOptions"
                          data-vv-validate-on="blur">
                    <span slot="no-options">{{ $t('menuOrganization.emptyList') }}</span>
                </v-select>
            </div>
            <div class="vx-col sm:w-full md:w-1/3 lg:w-1/4 w-full">
                <label class="vs-input--label">{{ $t('root.status') }}</label>
                <v-select class=""
                          v-model="statusModel"
                          :clearable="false"
                          :options="status"
                          data-vv-validate-on="blur">
                    <span slot="no-options">{{ $t('menuOrganization.emptyList') }}</span>
                </v-select>
            </div>
            <div class="vx-col sm:w-full md:w-1/3 lg:w-1/4 w-full">
                <label class="vs-input--label">Tìm kiếm</label>
                <vs-input class="w-full"
                          v-model="searchQuery"
                          @input="updateSearchQuery" :placeholder="$t('button.search')"/>
            </div>
        </div>

        <div class="vx-row">
            <div class="vx-col w-full flex">
                <vs-button icon-pack="feather" @click="initScreen" class="mt-6">{{
                        $t('button.search')
                    }}
                </vs-button>
                <vs-button  class="mt-6 ml-5" icon-pack="feather" icon="icon-download" type="border" @click="exportFile">Tạo
                    báo cáo
                </vs-button>
            </div>
        </div>

        <!-- AgGrid Table -->
        <ag-grid-vue style="height: 55vh"
                     ref="agGridTable"
                     :components="components"
                     :gridOptions="gridOptions"
                     class="ag-theme-material w-100 my-4 ag-grid-table"
                     :columnDefs="columnDefs"
                     :defaultColDef="defaultColDef"
                     :rowData="registerPackageHistory"
                     :animateRows="true"
                     :pagination="true"
                     :localeText=" {noRowsToShow: this.$t('menuCamera.noRowsToShow')}"
                     :paginationPageSize="paginationPageSize"
                     :onGridReady="onGridReady"
                     :suppressPaginationPanel="true">
        </ag-grid-vue>

        <div class="vx-row items-center">
            <div class="vx-col md:w-1/2">
                <Pagination
                    :itemList="registerPackageHistory"
                    :currentPage="currentPage"
                    :paginationPageSize="paginationPageSize"
                    :gridApi="gridApi"
                />
            </div>
            <div class="vx-col md:w-1/2">
                <vs-pagination
                    :total="totalPages"
                    :max="maxPageNumbers"
                    v-model="currentPage"/>
            </div>
        </div>
    </div>
</template>
<script>
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Pagination from "../../../ui-elements/Pagination.vue"
import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue"
import vSelect from 'vue-select'
import DateRangePicker from '../../../../components/vue2-daterange-picker/components/DateRangePicker.vue'
import moment from 'moment'
import Vue from "vue";
import CellRendererPaymentStatus from "../package-camera/cell-renderer/CellRendererPaymentStatus";
import authConfig from '@/../auth_config.json';

export default {
    components: {
        AgGridVue,
        // Cell Renderer
        CellRendererStatus,
        Pagination,
        vSelect,
        DateRangePicker,
        moment,
        authConfig
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: this.$t('cameraHistory.registerPackage.columnDefs.alias'),
                    field: 'alias',
                    width: 70
                },
                {
                    headerName: this.$t('cameraHistory.registerPackage.columnDefs.packageName'),
                    field: 'cameraPackageResponseDTO.packageName',
                    width: 70
                },
                {
                    headerName: this.$t('menuPackage.time'),
                    field: 'time',
                    width: 50
                },
                {
                    headerName: this.$t('cameraHistory.registerPackage.columnDefs.startDate'),
                    field: 'startDuration',
                    width: 80
                },
                {
                    headerName: this.$t('cameraHistory.registerPackage.columnDefs.endDate'),
                    field: 'endDuration',
                    width: 80
                },
                {
                    headerName: this.$t('menuPackage.amount'),
                    field: 'amount',
                    width: 70
                },
                {
                    headerName: this.$t('menuPackage.paymentType'),
                    field: 'paymentType',
                    width: 70,
                    cellRendererFramework: Vue.extend(CellRendererPaymentStatus)
                },
                {
                    headerName: this.$t('cameraHistory.registerPackage.columnDefs.colUpdateNow'),
                    field: 'modifiedDate',
                    width: 100
                },
                {
                    headerName: this.$t('cameraHistory.registerPackage.columnDefs.status'),
                    field: 'status',
                    width: 80,
                    cellRendererFramework: 'CellRendererStatus',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererStatus,
            },
            registerPackageHistory: [],
            cameraGroupId: null,
            selectedCamera: null,
            cameraOptions: [],
            dateRange: {
                endDate: new Date(),
                startDate: (() => {
                    let date = new Date();
                    date.setDate(date.getDate() - 6);
                    date.setHours(0, 0, 0);
                    return date;
                })()
            },
            selectedStatus: null,
            status: [
                {value: null, label: this.$t('cameraHistory.registerPackage.status.all')},
                {value: 0, label: this.$t('cameraHistory.registerPackage.status.pending')},
                {value: 1, label: this.$t('cameraHistory.registerPackage.status.accept')},
                {value: 2, label: this.$t('cameraHistory.registerPackage.status.reject')},
                {value: 3, label: this.$t('cameraHistory.registerPackage.status.cancel')},
                {value: 4, label: this.$t('cameraHistory.registerPackage.status.waiting')},
                {value: 5, label: this.$t('cameraHistory.registerPackage.status.abort')},
                {value: 6, label: this.$t('cameraHistory.registerPackage.status.expires')},
                {value: 6, label: this.$t('cameraHistory.registerPackage.status.failure')},
            ],
        }
    },
    async created() {
        await this.getCamera();
        this.initScreen();
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        cameraModel: {
            get() {
                let object = this.cameraOptions.find(el => el.value == this.selectedCamera);
                if (!object) return;
                return {
                    label: object.label,
                    value: this.selectedCamera
                }
            },
            set(object) {
                this.selectedCamera = object ? object.value : null;
                this.initScreen();
            }
        },
        statusModel: {
            get() {
                let object = this.status.find(el => el.value == this.selectedStatus);
                if (!object) return;
                return {
                    label: object.label,
                    value: this.selectedStatus
                }
            },
            set(object) {
                this.selectedStatus = object ? object.value : null;
                this.initScreen();
            }
        }
    },
    methods: {
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        exportFile() {
            this.$vs.loading();
            this.dateRange.startDate = new Date(this.dateRange.startDate.setHours(0, 0, 0));
            this.dateRange.endDate = new Date(this.dateRange.endDate.setHours(23, 59, 59));
            let url = `/camera-package-history/export?startDate=${this.dateRange.startDate.getTime()}
                        &endDate=${this.dateRange.endDate.getTime()}${this.selectedCamera ?
                '&cameraId=' + this.selectedCamera : ''}${this.selectedStatus != null ? '&status=' + this.selectedStatus : ''}`;
            this.$vs.loading();
            this.$crm.get(url).then((response) => {
                this.$vs.loading.close();
                window.open(authConfig.crmDomain + "file/" + response.data.fileName + "?access_token=" + localStorage.getItem("access_token"), "_blank");
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        initScreen() {
            this.$vs.loading();
            this.dateRange.startDate = new Date(this.dateRange.startDate.setHours(0, 0, 0));
            this.dateRange.endDate = new Date(this.dateRange.endDate.setHours(23, 59, 59));
            let url = `/camera-package-history/find-log-by-org?startDate=${this.dateRange.startDate.getTime()}
                        &endDate=${this.dateRange.endDate.getTime()}${this.selectedCamera ?
                '&cameraId=' + this.selectedCamera : ''}${this.selectedStatus != null ? '&status=' + this.selectedStatus : ''}`;
            this.$vs.loading();
            this.$crm.get(url).then((response) => {
                this.registerPackageHistory = [];
                if (response.data.length > 0) {
                    this.registerPackageHistory = response.data.reduce((arr, index) => {
                        index.modifiedDate = moment(index.modifiedDate).format("DD/MM/YYYY HH:mm:ss");
                        index.startDuration = moment(index.startDuration).format("DD/MM/YYYY HH:mm:ss");
                        index.endDuration = moment(index.endDuration).format("DD/MM/YYYY HH:mm:ss");
                        index.amount = index.amount ? this.formatPrice(index.amount) : 0;
                        let camera = this.cameraOptions.find(c => c.alias == index.alias);
                        index.alias = camera ? camera.alias : index.alias;
                        index.cameraName = camera ? camera.label : '';
                        arr.push(index);
                        return arr;
                    }, []);
                }
                setTimeout(() => {
                    this.gridApi.sizeColumnsToFit();
                }, 200);
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getCamera() {
            return new Promise((resolve => {
                let url = '/camera/all';
                this.$vs.loading();
                this.$crm.post(url).then((response) => {
                    this.cameraOptions = response.data.map(el => {
                        return {
                            label: el.cameraName,
                            value: el.id,
                            alias: el.aliasId
                        }
                    })
                    this.cameraOptions.unshift({
                        value: null,
                        label: this.$t('cameraHistory.registerPackage.status.all')
                    })
                    resolve(true);
                    this.$vs.loading.close();
                }).catch((err) => {
                    resolve(true);
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            }))
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    }
}
</script>
